import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";

const login = (requestBody: string) => {
  return axios
    .post(`${BASE_URL}/users/authenticate`, requestBody, {
      headers: { "Content-Type": "application/json","tenantId": 'saravanas_PMSCLI584', token: "" },
    })
    .then((response) => {
      let data = {};
      if (response.status === 200 && response != null) {
        data = response.data;
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
       throw error;
    });
};


export { login  };
